import { gql } from '@apollo/client/core';

export default gql`
  query FetchPatientProviderContacting($searchOptions: SearchPatientProviderContactingDto!) {
    patientProviderContacting(searchOptions: $searchOptions) {
      totalRecords
      patientProviderContacting {
        patientMissingInfoId
        patient {
          id
          fullName
          mrn
          brightreePatientId
          brightreeLink
          betternightLink
          betterNightUserId
          orderingProviderName
          isTOUAccepted
        }
        orderingProvider {
          id
          name
          originalName
          firstName
          lastName
          group {
            id
            name
            originalName
          }
          brightreeNavigationLink
        }
        orderingProviderContactInfo {
          id
          sugarRecordId
          sugarName
          sugarContactNavigationLink
          preferredCommunicationMethod
          communicationMethodDetails
        }
        patientMissingInfo {
          id
          createdBy
          createdAt
          updatedBy
          updatedAt
          isActive
          submittedDate
          salesOrder
          salesOrderLink
          nextContactDate
          nextAttempt
          nextAttemptSelectedDate
          firstAttemptDate
          receivedAllDate
          closedDate
          referralContactNotificationFailedDate
          demoName
          demoDob
          demoPhone
          medInsPayor
          medInsPolicyNo
          medInsIdCard
          authTricareAttest
          authColMedQ8
          consNoteSigned
          consNoteFaceToFace
          consNotePriorToStudy
          consNoteDiscussingBenefit
          consNoteContSymptoms
          consNoteSecondaryDx
          consNoteDiscussingNonCompliance
          homeStudySigned
          homeStudyPecos
          homeStudyDxCode
          homeStudyAuth95800
          homeStudyAuth95806
          baseStudySigned
          baseStudyScored4
          baseStudyAhi5
          baseStudyCompletedInLab
          titrationStudySigned
          titrationStudyScored4
          papTherapySigned
          papTherapyPecos
          papTherapyDxCode
          papTherapyPressure
          papAuthE0601
          papAuthE0470
          papAuthE0471
          papAuthE0562
          papAuthA4604
          papAuthA7030
          papAuthA7031
          papAuthA7032
          papAuthA7033
          papAuthA7034
          papAuthA7035
          papAuthA7036
          papAuthA7037
          papAuthA7038
          papAuthA7039
          papAuthA7046
          comments
          latestReferralContactHistory {
            id
            createdBy
            createdAt
            updatedBy
            updatedAt
            attempt
            status
            statusDetails
            sentDate
            notificationType
          }
        }
      }
    }
  }
`;
