import { gql } from '@apollo/client/core';

export default gql`
  query FetchPatientMissingInfo($brightreeId: Float!) {
    patientMissingInfo(brightreeId: $brightreeId) {
      patientId
      patient {
        id
        fullName
        mrn
        brightreePatientId
        brightreeLink
        betternightLink
        betterNightUserId
        orderingProviderName
      }
      orderingProvider {
        id
        name
        originalName
        firstName
        lastName
        group {
          id
          name
          originalName
        }
        brightreeNavigationLink
        contactInfo {
          id
          sugarRecordId
          preferredCommunicationMethod
          communicationMethodDetails
        }
      }
      patientMissingInfo {
        id
        createdBy
        createdAt
        updatedBy
        updatedAt
        isActive
        isProviderContactingRecord
        submittedDate
        salesOrder
        salesOrderLink
        nextContactDate
        nextAttempt
        demoName
        demoDob
        demoPhone
        medInsPayor
        medInsPolicyNo
        medInsIdCard
        authTricareAttest
        authColMedQ8
        consNoteSigned
        consNoteFaceToFace
        consNotePriorToStudy
        consNoteDiscussingBenefit
        consNoteContSymptoms
        consNoteSecondaryDx
        consNoteDiscussingNonCompliance
        homeStudySigned
        homeStudyPecos
        homeStudyDxCode
        homeStudyAuth95800
        homeStudyAuth95806
        baseStudySigned
        baseStudyScored4
        baseStudyAhi5
        baseStudyCompletedInLab
        titrationStudySigned
        titrationStudyScored4
        papTherapySigned
        papTherapyPecos
        papTherapyDxCode
        papTherapyPressure
        papAuthE0601
        papAuthE0470
        papAuthE0471
        papAuthE0562
        papAuthA4604
        papAuthA7030
        papAuthA7031
        papAuthA7032
        papAuthA7033
        papAuthA7034
        papAuthA7035
        papAuthA7036
        papAuthA7037
        papAuthA7038
        papAuthA7039
        papAuthA7046
        comments
      }
      contactHistories {
        id
        createdBy
        createdAt
        updatedBy
        updatedAt
        attempt
        status
        statusDetails
        sentDate
        notificationType
      }
    }
  }
`;
